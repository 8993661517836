/**
 * @desc - rippleエフェクト
 */
 /*
(function() {
    var ripple,
        ripples,
        RippleEffect,
        loc,
        cover,
        coversize,
        style,
        x,
        y,
        pos,
        i,
        num;

    //クラス名rippleの要素を取得
    ripples = document.querySelectorAll(".js-ripple");

    //位置を取得
    RippleEffect = function(e) {
        ripple = this; //クリックされたボタンを取得
        cover = document.createElement("span"); //span作る
        coversize = ripple.offsetWidth; //要素の幅を取得
        loc = ripple.getBoundingClientRect(); //絶対座標の取得
        x = e.pageX - loc.left - window.pageXOffset - coversize / 2;
        y = e.pageY - loc.top - window.pageYOffset - coversize / 2;
        pos =
            "top:" +
            y +
            "px; left:" +
            x +
            "px; height:" +
            coversize +
            "px; width:" +
            coversize +
            "px;";

        //spanを追加
        ripple.appendChild(cover);
        cover.setAttribute("style", pos);
        cover.setAttribute("class", "is-show"); //クラス名追加
        //しばらくしたらspanを削除
        setTimeout(function() {
            var list = document.getElementsByClassName("is-show");
            for (var i = list.length - 1; i >= 0; i--) {
                //末尾から順にすべて削除
                list[i].parentNode.removeChild(list[i]);
            }
        }, 2000);
    };
    for (i = 0, num = ripples.length; i < num; i++) {
        ripple = ripples[i];
        ripple.addEventListener("mousedown", RippleEffect);
    }
})();
*/
